<template>
  <div class="huan main-frame" :class="isEn?'huan-en':''">
    <!-- 内容1 -->
    <div class="cont-1 relative">
      <el-image lazy class="banner-1" :src="require('../assets/soft/banner.jpg')"/>
      <div class="text-1 left">
        <h2 class="h-b gapB24">{{$t("soft_h_1")}}</h2>
        <p class="p2 fz16 fw400">{{$t("soft_p_1")}}</p>
      </div>
    </div>

    <!-- 内容2 -->
    <div class="cont-2">
        <div class="home-con">
          <ul class="ul-content left">
            <li>
              <h2 class="h2-7"><span class="span1 fw700 fz48">{{$t('hh2_1_1')}}</span>&nbsp;<span class="p3">{{$t('hh2_2_1')}}</span></h2>
              <p class="p2 gapT10">{{$t('hh2_1')}}</p>
            </li>
            <li>
              <h2 class="h2-7"><span class="span1 fw700 fz48">{{$t('hh2_1_2')}}</span>&nbsp;<span class="p3">{{$t('hh2_2_2')}}</span></h2>
              <p class="p2 gapT10">{{$t('hh2_2')}}</p>
            </li>
            <li>
              <h2 class="h2-7"><span class="span1 fw700 fz48">{{$t('hh2_1_3')}}</span>&nbsp;<span class="p3">{{$t('hh2_2_3')}}</span></h2>
              <p class="p2 gapT10">{{$t('hh2_3')}}</p>
            </li>
            <li>
              <h2 class="h2-7"><span class="span1 fw700 fz48">{{$t('hh2_1_4')}}</span>&nbsp;<span class="p3">{{$t('hh2_2_4')}}</span></h2>
              <p class="p2 gapT10">{{$t('hh2_4')}}</p>
            </li>
          </ul> 
        </div>
    </div>

    <!-- 内容3 -->
    <div class="cont-3 padd100-100">
      <h2 class="h-1 gapB20 fw700">{{$t("hh15_1")}}</h2>
      <p class="gapB64 fz14 fw400">{{$t("hh15_2")}}</p>
      <div class="home-con">
        <ul class="ul-content">
          <li v-for="(item,index) in photoData" class="relative" :key="index">
            <img :src="item.imgUrl" alt=""/>
            <h2 class="p1 fz24 fw700">{{$t(item.name)}}</h2>        
          </li>
        </ul> 
      </div> 
    </div>

    <!-- 内容4 -->
    <div class="cont-4 padd100-100">
      <h2 class="h-1 gapB20 fw700">{{$t("hh14_1")}}</h2>
      <p class="gapB64 fz14 fw400">{{$t("hh14_2")}}</p>
      <div class="home-con">
        <img :src="require('../assets/home/home_3_img.png')"  />
      </div>
    </div>

    <!-- 内容5 -->
    <div class="cont-5 padd100-100">
        <h2 class="h-1 gapB80">{{$t("hh16")}}</h2>
        <div class="home-con">
          <ul class="ul-content left">
            <li>
              <h3 class="fz24 fw700 gapB48">{{$t('hh16_1')}}</h3>
              <p class="p2">{{$t('hh16_p_1')}}</p>
            </li>
            <li>
              <h3 class="fz24 fw700 gapB48">{{$t('hh16_2')}}</h3>
              <p class="p2">{{$t('hh16_p_2')}}</p>
            </li>
            <li>
              <h3 class="fz24 fw700 gapB48">{{$t('hh16_3')}}</h3>
              <p class="p2">{{$t('hh16_p_3')}}</p>
            </li>
            <li>
              <h3 class="fz24 fw700 gapB48">{{$t('hh16_4')}}</h3>
              <p class="p2">{{$t('hh16_p_4')}}</p>
            </li>
          </ul>
        </div>
    </div>

    <!-- 内容6 -->
    <div class="cont-6 padd100-100">
      <h2 class="h-1 gapB20 fw700">{{$t("soft_h_6")}}</h2>
      <p class="gapB80 fz14 fw400">{{$t("soft_p_6")}}</p>
      <div class="cont-6-box relative">
        <img class="cont-6-img1" :src="require('../assets/soft/cont_6_img1.png')"  />
        <img class="cont-6-img2" :src="require('../assets/soft/cont_6_img2.png')"  />
      </div> 
    </div>

    <!-- 内容7 -->
    <div class="cont-7">
      <h2 class="h-1 gapB20 fw700">{{$t("soft_h_7")}}</h2>
      <p class="gapB80 fz14 fw400">{{$t("soft_p_7")}}</p>
      <div class="cont-7-box left">
        <div class="box1 flex-con">
          <div class="text-warp1">
            <h2 class="h2-7 gapB20">{{$t('soft_con_h1')}}</h2>
            <p class="p2 fz16">{{$t('soft_con_p1')}}</p>
          </div>
          <img class="cont-7-img1" :src="require('../assets/soft/cont_7_img1.png')"  />
        </div>
        <div class="box2 flex-con relative">
          <img class="cont-7-img2" :src="require('../assets/soft/cont_7_img2.png')"  />
          <div class="text-warp2">
            <h2 class="h2-7 gapB20">{{$t('soft_con_h2')}}</h2>
            <p class="p2 fz16">{{$t('soft_con_p2')}}</p>
          </div>
        </div>
        <div class="box3 flex-con relative">
          <div class="text-warp3">
            <h2 class="h2-7 gapB20">{{$t('soft_con_h3')}}</h2>
            <p class="p2 fz16">{{$t('soft_con_p3')}}</p>
          </div>
          <img class="cont-7-img3" :src="require('../assets/soft/cont_7_img3.png')"  />
        </div>
      </div> 
    </div>

    
  </div>
</template>

<script>
export default {
  data() {
    return {
      //业务模式数据
      photoData:[
        {
          imgUrl:require('../assets/home/home_5_img2.png'),
          name:'hh5_con_1'
        },
        {
          imgUrl:require('../assets/home/home_5_img3.png'),
          name:'hh5_con_2'
        },
        {
          imgUrl:require('../assets/home/home_5_img1.png'),
          name:'hh5_con_3'
        },
        {
          imgUrl:require('../assets/home/home_5_img4.png'),
          name:'hh5_con_4'
        },
        {
          imgUrl:require('../assets/home/home_5_img5.png'),
          name:'hh5_con_5'
        },
        {
          imgUrl:require('../assets/home/home_5_img6.png'),
          name:'hh5_con_6'
        },
      ],
    };
  },
  computed:{
    isEn:function(){
      return this.$store.state.isEn
    },
  },
  mounted(){

  },
  methods: {

  },
};
</script>


<style lang="scss" scoped>
// 公共样式
.home-con{
  display: flex;
  justify-content: center;
  width: 100%;
}
.ul-content{
  width:1200px;
  display: flex;
}

//内容1
.cont-1{
  width: 100%;
  .banner-1{
    height: 700px;
  }
  .text-1 {
    position: absolute;
    top: 196px;
    left: 360px;
    .h-b{
      color: #242933;
    }
  }
}

// 内容2
.cont-2{
  opacity: 0.9;
  background: linear-gradient(180deg,#020111 10%, #3a3a52 100%);
  border-radius: 4px;
  padding: 128px 0 136px 0;
  box-sizing: border-box;
  width: 1440px;
  margin: -60px auto 0;
  li{
    flex: 1;
    border-right: 1px solid #d9d9d9;
    padding-left: 96px;
    h2{
      margin-bottom: 0;
      color: #6aa1ff;
      height: 56px;
      display: flex;
      align-items: start;
      position: relative;
    }
    p{
      color: #fff;
    }
    .span1{
      color: #6aa1ff;
      padding-right: 20px;
      display: inline-block;
      height: 56px;
      display: flex;
      align-items: start;
      line-height: 48px;
      position: relative;
      top: -4px;
      letter-spacing: 2px;
    }
  }
  li:nth-child(4){
    border-right: none;
  }
}

// 内容4
.cont-4{
  background: #242933;
  box-sizing: border-box;
  img{
    width: 1360px;
    height: 942px;
  }
  h2{
    color: #fff;
  }
  p{
    color: rgba(255,255,255,0.80);
  }
}

// 内容6
.cont-6{
  width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  p{
    color: #788496;
  }
  .cont-6-box{
    width: 100%;
    height: 872px;
    .cont-6-img1{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 640px;
    }
    .cont-6-img2{
      position: absolute;
      left: 8px;
      top: 56px;
      width: 1424px;
      height: 816px;
    }
  }
}

// 内容7
.cont-7{
  width: 1440px;
  margin: 0 auto;
  padding-bottom: 96px;
  box-sizing: border-box;
  p{
    color: #788496;
  }
  .cont-7-box{
    width: 100%;
    height: 1533px;
    background: linear-gradient(180deg,#e8f3ff, rgba(190,218,255,0.80) 50%, #e8f3ff 100%);
    .flex-con{
      display: flex;
      .p2{
        line-height: 24px;
        width: 280px;
        color: #393F47;
        padding-bottom: 44px;
        position: relative;
      }
      .p2::after{
        content: "";
        width: 40px;
        height: 4px;
        background: #165dff;
        position: absolute;
        left: 0;
        bottom: 4px;
      }
      .h2-7{
        color: #181d24;
      }
    }
    .text-warp1,.text-warp3{
      width: 580px;
      padding-left: 220px;
    }
    .text-warp1{
      padding-top: 84px;
    }
    .text-warp3{
      padding-top: 56px;
    }
    .text-warp2{
      width: 516px;
      padding-left: 16px;
      padding-top: 86px;
    }
    .box2{
      padding-left: 80px;
      top: -62px;
    }
    .box3{
      top: -91px;
    }
    .cont-7-img1{
      width: 820px;
      height: 700px;
    }
    .cont-7-img2{
      width: 844px;
      height: 552px;
    }
    .cont-7-img3{
      width: 820px;
      height: 420px;
    }
  }
}

// 内容3
.cont-3{
  background: #f8f9fb;
  p{
    color: #788496;
  }
  .ul-content{
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      width: 387px;
      height: 216px;
      img{
        width: 387px;
        height: 216px;
      }
      h2{
        position: absolute;
        left: 32px;
        top: 24px;
      }
    }
    li:nth-child(1),
    li:nth-child(2),
    li:nth-child(3){
      margin-bottom: 20px;
    }
  }
}

// 内容5
.cont-5{
  background-image: url(../assets/home/home_6_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 728px;
  box-sizing: border-box;
  .ul-content{
    justify-content: center;
    li{
      flex: 1;
      text-align: left;
      padding: 48px 32px 0 32px;
      height: 400px;
      box-sizing: border-box;
    }
    li:hover{
      background-image: url(../assets/home/home_6_conbg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      height: 400px;
      box-sizing: border-box;
      h3{
        color: #fff;
      }
      p{
        color: rgba(255,255,255,0.80);
      }
    }
  }
}
/*IPone 428*/
@media (max-width: 428px) {
  
}
</style>